 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="middle"
        @hide="$emit('generateEmailHide')"
    >
        <div slot="header">
            <h2 class="font-normal">Generate E-mail</h2>
        </div>
        <div slot="body">
            <form @submit.prevent="">
                <div class="flex flex__justify-between">
                    <div class="w__min-50-p">
                        <modal-field label="Language">
                            <label
                                v-for="(item, key) in value.langCodes"
                                :key="item"
                                class="flex items-center mt-4"
                            >
                                <input v-model="language" type="radio" :value="key" class="mr-2" @change="updateTopic(topic)" />
                                {{ item }}
                            </label>
                        </modal-field>
                    </div>
                    <div class="w__min-50-p">
                        <modal-field label="Send from:">
                            <label 
                                v-for="item in value.emailsFrom"
                                :key="item"
                                class="flex items-center mt-4"
                            >
                                <input v-model="from" type="radio" :value="item" class="mr-2" @change="clear">
                                {{ item }}
                            </label>
                        </modal-field>
                    </div>
                </div>

                <div class="flex flex__justify-between mt-6">
                    <div class="w__min-50-p">
                        <modal-field label="Recipient type">
                            <label
                                v-for="(item, key) in value.recipientTypes"
                                :key="item" 
                                class="flex items-center mt-4">
                                <input v-model="recipient" type="radio" :value="key" class="mr-2" @change="clear">
                                {{ item }}
                            </label>
                        </modal-field>
                    </div>
                </div>

                <modal-field label="Recipient E-mail address" class="mt-6">
                    <multiselect
                        multiple
                        v-model="to"
                        :options="value.emailsTo[recipient]"
                        class="relative"
                    >
                    </multiselect>
                </modal-field>

                <modal-field label="Topic" class="mt-3">
                    <multiselect
                        v-model="topic"
                        :options="value.topics"
                        class="relative"
                        label="name"
                        track-by="name"
                        @select="updateTopic"
                    >
                        <template slot="option" slot-scope="{ option }">
                            {{ option.name }}
                        </template>
                    </multiselect>
                </modal-field>

                <div v-if="false" class="cp-flex mt-6">
                    <v-checkbox v-model="correspondence" class="mr-3" title="Add to existing correspondence" />
                </div>

                <modal-field v-if="correspondence && false" label="List of existing correspondences with selected recipient" class="mt-6">
                    <multiselect
                        v-model="correspondence_name"
                        :options="['Apple', 'New year']"
                        class="relative"
                    >
                    </multiselect>
                </modal-field>

                <modal-field label="Header" class="mt-6">
                    <div class="relative text-sm leading-tight"><input type="text" v-model="header"></div>
                </modal-field>

                <modal-field label="Body" class="mt-6">
                    <textarea v-model="body" rows="8"></textarea>
                </modal-field>
            </form>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button
                        class="px-10 btn-primary"
                        @click="save"
                    >Send</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import VCheckbox from '~/components/VCheckbox'

import axios from 'axios';
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },

    components: {
        VCheckbox,
    },

    name: 'GenerateEmailModal',

    data() {
        return {
            correspondence: false,
            correspondence_name: '',
            language: Object.keys(this.value.langCodes)[0],
            from: this.value.emailsFrom[0],
            to: [],
            topic: null,
            body: '',
            recipient: Object.keys(this.value.recipientTypes)[0],
            header: '',
        }
    },

    methods: {
        clear() {
            this.to = [];
        },
        async updateTopic(value) {
            this.header = '';
            this.body = '';
            
            try {
                const { data: { data } } = await axios.get(`${this.$apiUrl.consignments.base}/${ this.value.id }/canned-response-template?id=${value.id}&lang=${this.language}`)

                this.header = data.header;
                this.body = data.body;
            } catch(error) {
                const message = error.response.statusText ? error.response.statusText : error.response.data.errors.join(', ');
                this.$snotify.error(message);
            }
        },
        async save() {
            this.loading = true;

            if (!this.to.length) {
                this.$snotify.warning('Recipient E-mail address required!')

                return;
            }

            if (!this.topic) {
                this.$snotify.warning('Topic required!')

                return
            }

            if (!this.header) {
                this.$snotify.warning('Header required!')

                return
            }

            if (!this.body) {
                this.$snotify.warning('body required!')

                return
            }

            await axios.post(this.$apiUrl.consignments.cannedResponse, {
                fromEmail: this.from,
                toEmails: this.to,
                subject: this.topic.name,
                body: this.body,
                header: this.header,
            });

            this.$emit('generateEmailHide');
            this.$emit('refreshTable');

            this.loading = false;
        }
    }
}
</script>
